import { Environment } from '@integration-frontends/apps/common/utils/environment-types';

export const environment = {
  production: true,
  bfBaseUrl: 'https://brandfolder.com/api/v1/contentsync/shopify/bf-api-proxy',
  shopifyBackendBaseUrl: 'https://brandfolder.com/api/v1/contentsync/shopify',
  contentSyncFetchUrl: 'https://brandfolder.com/api/v1/contentsync/shopify/product_image',
  contentSyncUrlShopParam: 'https://brandfolder.com/api/v1/contentsync/shopify/install?shop=',
  shopifyApiKey: process.env.SHOPIFY_API_KEY,
  segmentWriteKey: process.env.SEGMENT_WRITE_KEY,
  environment: Environment.Production,
};
